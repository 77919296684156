import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import ParticleBreathing from "dupes/particle-breathing";
import styled, { createGlobalStyle } from "styled-components";
import FullheightContainer from "react-div-100vh";
import ParticleBreathingImage from "images/particle-breathing.jpg";

const GlobalStyle = createGlobalStyle`
  * {
      font-family: Helvetica, Arial, sans-serif;
      font-weight: 400;
  }
`;

const Container = styled(FullheightContainer)`
  padding: 3rem;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-family: Helvetica, Arial, sans-serif;
`;

const StyledLink = styled(Link)`
  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: #333;
    text-decoration: none;
  }
`;

const LinkImage = styled.img`
  width: 120px;
  height: 120px;
`;

function Gallery() {
  return (
    <Container>
      <h1>dupe</h1>
      <h3>visuals to ease your tired mind</h3>
      <LinkContainer>
        <StyledLink to="/particle-breathing">
          <LinkImage src={ParticleBreathingImage} alt="particle breathing" />
          <div>particle breathing</div>
        </StyledLink>
      </LinkContainer>
    </Container>
  );
}

function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/" component={ParticleBreathing} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
