import React, {
  Suspense,
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import styled from "styled-components";
import FullheightContainer from "react-div-100vh";
import * as THREE from "three";
import { Canvas, useFrame, useThree, extend } from "react-three-fiber";
import { ChromePicker, ColorResult } from "react-color";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
extend({
  EffectComposer,
  ShaderPass,
  RenderPass,
  AfterimagePass,
  UnrealBloomPass,
});

declare global {
  namespace JSX {
    interface IntrinsicElements {
      effectComposer: any;
      shaderPass: any;
      renderPass: any;
      afterimagePass: any;
      unrealBloomPass: any;
    }
  }
}

const secondRingColor = new THREE.Color("#1133ff");
const lightColor = new THREE.Color("white");

const buttonWhite = "white";

const Swarm = React.memo(
  ({ count, color }: { count: number; color?: THREE.Color }) => {
    const mesh = useRef<THREE.InstancedMesh>();
    const light = useRef<THREE.PointLight>();

    const dummy = useMemo(() => new THREE.Object3D(), []);
    // Generate some random positions, speed factors and timings
    const particles = useMemo(() => {
      const temp = [];
      for (let i = 0; i < count; i++) {
        const t = Math.random() * 100;
        const factor = 20 + Math.random() * 20;
        const speed = 0.01 + Math.random() / 200;
        const xFactor = -50 + Math.random() * 100;
        const yFactor = -50 + Math.random() * 100;
        const zFactor = -50 + Math.random() * 100;
        temp.push({
          t,
          factor,
          speed,
          xFactor,
          yFactor,
          zFactor,
          mx: 0,
          my: 0,
        });
      }
      return temp;
    }, [count]);
    // The innards of this hook will run every frame
    useFrame((state) => {
      if (mesh.current) {
        // Run through the randomized data to calculate some movement
        particles.forEach((particle, i) => {
          let { t, factor, speed, xFactor, yFactor, zFactor } = particle;
          // There is no sense or reason to any of this, just messing around with trigonometric functions
          t = particle.t += speed / 2;
          const a = Math.cos(t) + Math.sin(t * 1) / 10;
          const b = Math.sin(t) + Math.cos(t * 2) / 10;
          const s = Math.cos(t);
          particle.mx += particle.mx * 0.01;
          particle.my += particle.my * 0.01;
          // Update the dummy object
          dummy.position.set(
            (particle.mx / 10) * a +
              xFactor +
              Math.cos((t / 10) * factor) +
              (Math.sin(t * 1) * factor) / 10,
            (particle.my / 10) * b +
              yFactor +
              Math.sin((t / 10) * factor) +
              (Math.cos(t * 2) * factor) / 10,
            (particle.my / 10) * b +
              zFactor +
              Math.cos((t / 10) * factor) +
              (Math.sin(t * 3) * factor) / 10
          );
          dummy.scale.set(s, s, s);
          dummy.rotation.set(s * 5, s * 5, s * 5);
          dummy.updateMatrix();
          // And apply the matrix to the instanced item
          mesh && mesh.current && mesh.current.setMatrixAt(i, dummy.matrix);
        });
        mesh.current.instanceMatrix.needsUpdate = true;
      }
    });

    return (
      <>
        <pointLight ref={light} decay={5} intensity={5} color={lightColor}>
          <mesh>
            <ringGeometry attach="geometry" args={[5, 7, 32]} />
            <meshBasicMaterial attach="material" color={secondRingColor} />
          </mesh>
          <mesh>
            <ringGeometry attach="geometry" args={[3, 5, 32]} />
            <meshBasicMaterial attach="material" color={lightColor} />
          </mesh>
        </pointLight>
        <instancedMesh
          ref={mesh}
          args={[null, null, count] as any}
          receiveShadow={false}
          castShadow={false}
        >
          <dodecahedronBufferGeometry attach="geometry" args={[3.5, 0]} />
          <meshStandardMaterial attach="material" color={color} />
        </instancedMesh>
      </>
    );
  }
);

const Effect = React.memo(() => {
  const composer = useRef<EffectComposer>();
  const { scene, gl, size, camera } = useThree();

  const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [
    size,
  ]);
  useEffect(() => {
    gl.outputEncoding = THREE.GammaEncoding;
  }, [gl]);
  useEffect(
    () => composer.current && composer.current.setSize(size.width, size.height),
    [size]
  );
  useFrame(() => composer.current && composer.current.render(), 1);

  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      <afterimagePass attachArray="passes" uniforms-damp-value={0.3} />
      <unrealBloomPass attachArray="passes" args={[aspect, 1.2, 0.7, 0.1]} />
      <shaderPass
        attachArray="passes"
        args={[FXAAShader]}
        uniforms-resolution-value={[1 / size.width, 1 / size.height]}
        renderToScreen
      />
    </effectComposer>
  );
});

const Dolly = React.memo(
  ({ zoom, rotate }: { zoom?: boolean; rotate?: boolean }) => {
    useFrame(({ clock, camera }) => {
      const pauseScaler = 1.8; // this determines how long the zoom will pause for at apexes, a smaller number is a shorter pause
      const zoomSpeed = 1.1; // this will determine how quickly the camera will move in and out past apex, a smaller number is a slower zoom
      const sin = Math.sin(clock.getElapsedTime() / pauseScaler);
      const sign = sin > 0 ? 1 : -1;
      const exponent = zoomSpeed;

      if (zoom) {
        camera.position.z =
          25 + sign * (1 - Math.pow(1 - Math.abs(sin), exponent)) * 35;
        camera.updateProjectionMatrix();
      }

      if (rotate) {
        camera.rotation.x = sin * 0.3;
        camera.rotation.y = sin * 0.3;
        camera.rotateZ(-0.009);
      } else {
        camera.rotation.x = 0;
        camera.rotation.y = 0;
      }
    });

    return null;
  }
);

const FullScreen = styled(FullheightContainer)`
  background: black;
  max-width: 100%;
  width: 100vw;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: ${buttonWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  left: 0px;
  color: ${buttonWhite};
  background: none;
  border: none;
  font-size: 3rem;
  outline: none;
  padding: 1rem;
  text-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9);
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
`;

const Button = styled.button<{ selected?: boolean }>`
  background: ${({ selected }) => (selected ? buttonWhite : "transparent")};
  color: ${({ selected }) => (selected ? "black" : buttonWhite)};
  text-transform: uppercase;
  padding: 1rem;
  border: none;
  outline: none;
  border-radius: 50%;
  margin: 0 8px;
  box-shadow: ${({ selected }) =>
    selected ? "0px 0px 20px rgba(255, 255, 255, 0.8)" : "none"};
`;

const useToggle = (
  defaultValue: boolean
): [boolean, (e: React.SyntheticEvent) => void] => {
  const [value, setValue] = useState(defaultValue);

  const toggleValue = useCallback(
    (e: React.SyntheticEvent) => {
      setValue(!value);
      e.stopPropagation();
      e.preventDefault();
    },
    [value]
  );

  return [value, toggleValue];
};

const ParticleBreathing = React.memo(() => {
  const [zoom, toggleZoom] = useToggle(true);
  const [rotate, toggleRotate] = useToggle(true);
  const [color, setColor] = useState("#f00");

  const handleColorChange = useCallback((colorResult: ColorResult) => {
    setColor(colorResult.hex);
  }, []);
  const threeColor = useMemo(() => new THREE.Color(color), [color]);

  const [showOverlay, setShowOverlay] = useState(false);

  const openOverlay = useCallback(() => {
    if (!showOverlay) {
      setShowOverlay(true);
    }
  }, [showOverlay]);

  const closeOverlay = useCallback(
    (e: React.SyntheticEvent) => {
      if (showOverlay) {
        setShowOverlay(false);
      }
      e.preventDefault();
      e.stopPropagation();
    },
    [showOverlay]
  );

  return (
    <FullScreen onClick={openOverlay}>
      <Canvas camera={{ fov: 95, position: [0, 0, 70] }} colorManagement>
        <Swarm color={threeColor} count={500} />
        <Suspense fallback={null}>
          <Effect />
        </Suspense>
        <Dolly zoom={zoom} rotate={rotate} />
      </Canvas>
      {showOverlay && (
        <Overlay>
          <CloseButton onClick={closeOverlay}>✕</CloseButton>
          <Row>
            <Button onClick={toggleZoom} selected={zoom}>
              <span role="img" aria-label="zoom">
                🔎
              </span>
            </Button>
            <Button onClick={toggleRotate} selected={rotate}>
              <span role="img" aria-label="rotate">
                🔄
              </span>
            </Button>
          </Row>

          <Row>
            <ChromePicker
              disableAlpha
              color={color}
              onChange={handleColorChange}
            />
          </Row>
        </Overlay>
      )}
    </FullScreen>
  );
});

export default ParticleBreathing;
